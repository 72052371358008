export class SystemController {
  static login (params, axiosOption, httpOption) {
    return doUrl('admin/upms/login/doLogin', 'post', params, axiosOption, httpOption);
  }

  static logout (params, axiosOption, httpOption) {
    return doUrl('admin/upms/login/doLogout', 'post', params, axiosOption, httpOption);
  }

  static wxLogin (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/login/WxLogin', 'post', params, axiosOption, httpOption)
  }

  static changePassword (params, axiosOption, httpOption) {
    return doUrl('admin/upms/login/changePassword', 'post', params, axiosOption, httpOption);
  }

  static getLoginInfo (params, axiosOption, httpOption) {
    return doUrl('admin/upms/login/getLoginInfo', 'get', params, axiosOption, httpOption);
  }

  static getDictList (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDict/list', 'post', params, axiosOption, httpOption);
  }

  static getRoleList (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/list', 'post', params, axiosOption, httpOption);
  }

  static getRole (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/view', 'get', params, axiosOption, httpOption);
  }

  static deleteRole (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/delete', 'post', params, axiosOption, httpOption);
  }

  static addRole (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/add', 'post', params, axiosOption, httpOption);
  }

  static updateRole (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/update', 'post', params, axiosOption, httpOption);
  }

  static getUserList (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/list', 'post', params, axiosOption, httpOption);
  }

  static getUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/view', 'get', params, axiosOption, httpOption);
  }

  static resetUserPassword (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/resetPassword', 'post', params, axiosOption, httpOption);
  }

  static deleteUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/delete', 'post', params, axiosOption, httpOption);
  }

  static addUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/add', 'post', params, axiosOption, httpOption);
  }

  static updateUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysUser/update', 'post', params, axiosOption, httpOption);
  }

  static addDepartment (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/add', 'post', params, axiosOption, httpOption);
  }

  static deleteDepartment (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/delete', 'post', params, axiosOption, httpOption);
  }

  static updateDepartment (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/update', 'post', params, axiosOption, httpOption);
  }

  static getDepartmentList (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/list', 'post', params, axiosOption, httpOption);
  }

  // 菜单接口
  static getMenuPermList (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysMenu/list', 'post', params, axiosOption, httpOption);
  }

  static addMenu (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysMenu/add', 'post', params, axiosOption, httpOption);
  }

  static updateMenu (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysMenu/update', 'post', params, axiosOption, httpOption);
  }

  static deleteMenu (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysMenu/delete', 'post', params, axiosOption, httpOption);
  }

  static viewMenu (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysMenu/view', 'get', params, axiosOption, httpOption);
  }
  /**
   * @param params    {roleId, searchString}
   */
  static listRoleUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/listUserRole', 'post', params, axiosOption, httpOption);
  }

  static listNotInUserRole (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/listNotInUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params    {roleId, userIdListString}
   */
  static addRoleUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/addUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params    {roleId, userId}
   */
  static deleteRoleUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/deleteUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params {}
   */
  static queryRoleByPermCode (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysRole/listAllRolesByPermCode', 'post', params, axiosOption, httpOption);
  }
  // 操作日志
  static listSysOperationLog (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysOperationLog/list', 'post', params, axiosOption, httpOption);
  }
  // 在线用户
  static listSysLoginUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/loginUser/list', 'post', params, axiosOption, httpOption);
  }

  static deleteSysLoginUser (params, axiosOption, httpOption) {
    return doUrl('admin/upms/loginUser/delete', 'post', params, axiosOption, httpOption);
  }

  static getCurrentFormJson (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/system/getCurrentFormJson', 'get', params, axiosOption, httpOption);
  }

  static getHistoryByFormId (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/system/getHistoryByFormId', 'get', params, axiosOption, httpOption);
  }

  static getHistoryById (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/system/getHistoryById', 'get', params, axiosOption, httpOption);
  }

  static feedback (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/system/feedback', 'post', params, axiosOption, httpOption);
  }
}
