<template>
  <div ref="formWrapper" class="form-wrapper flex-col">
    <div class="flex flex-between m-b-10">
      <div class="flex-1">
        <div class="flex flex-start" v-if="filterFieldArray.length > 0">
          <el-select v-model="filterNameIndex" placeholder="请选择检索字段" class="m-r-10">
            <el-option v-for="item in filterFieldArray" :key="item.key" :label="item.label" :value="item.key"></el-option>
          </el-select>
          <el-input class="m-r-10" v-model="filterName" type="search" placeholder="" style="width: 200px;"></el-input>
          <el-button type="primary" @click="queryByParam($event, 1)">查询</el-button>
        </div>
      </div>
      <el-button type="primary" @click="onSubmit" class="btn-confirm">确定</el-button>
    </div>
    <div class="flex-1 tree-wrapper" v-if="isTree">
      <vxe-grid v-bind="gridOptions" @radio-change="onRadioChange"></vxe-grid>
    </div>
    <div ref="tableWrapper" class="tableWrapper flex-1" v-else>
      <vxe-table
        :row-config="{isCurrent: true, isHover: true, height: 120}"
        :radio-config="radioConfig"
        :data="dataListCopy" :loading="loading" @radio-change="onRadioChange" :max-height="maxHeight">
        <vxe-column type="radio" align="center" :width="50" />
        <vxe-column type="seq" title="序号" :width="50" />
        <vxe-column align="center" v-for="item in head" :key="item.columnName" :title="item.columnComment">
          <template #default="{ row }">
            {{ row[item.columnName] }}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="flex flex-end">
      <vxe-pager v-if="!isTree" :current-page.sync="page.currentPage" :page-size.sync="page.pageSize" size="mini"
        :total="page.total"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
        @page-change="queryByParam">
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import { OnlineFormController } from '@/api/OnlineFormController';
import thirdPartyMixin from '@/views/thirdParty/thirdPartyMixin.js';

export default {
  name: 'dataSelect',
  props: {
    value: {
      type: String
    },
    relativeTable: {
      type: Object
    },
    head: {
      type: Array
    },
    dataList: {
      type: Array
    },
    widget: {
      type: Object
    },
    total: {
      type: Number
    },
    columns: {
      type: Array,
      default: () => []
    },
    preParam: {
      type: Array,
      default: () => []
    }
  },
  mixins: [thirdPartyMixin],
  data () {
    const radioConfig = {
      labelField: 'delete_flag',
      trigger: 'row',
      checkMethod ({row}) {
        return !row.delete_flag
      }
    }
    return {
      dialogSelectItems: {},
      filterName: '', // 检索字段
      filterNameIndex: undefined,
      dataListCopy: [], // 由于需要重置数据 因此将从prop改到data
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      loading: false, // 表格数据加载中
      gridOptions: {},
      maxHeight: 600,
      radioConfig
    }
  },
  computed: {
    dialogParams () {
      return {
        value: this.value || this.thirdParams.value,
        relativeTable: this.relativeTable || this.thirdParams.relativeTable || {}
      }
    },
    // 需要显示的过滤字段
    filterFieldArray () {
      if (!Array.isArray(this.dialogParams.relativeTable.filterField)) return []
      const arr = this.dialogParams.relativeTable.filterField

      let temp = []

      if (arr.length === 0) return []

      arr.forEach((m) => {
        const label = this.head.find(item => item.columnName === m)?.columnComment
        temp.push({
          label,
          key: m
        })
      })

      return temp
    },
    isTree () {
      return this.relativeTable.isTree
    }
  },
  methods: {
    queryByParam (e, page = null) {
      if (page) this.page.currentPage = page
      let param = {
        dbLinkId: this.relativeTable.currentDblinkId,
        tableName: this.relativeTable.relativeFormId,
        pageParam: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize
        },
        filterDtoList: []
      }
      if (this.filterName) {
        param.filterDtoList = [
          {
            columnName: this.filterNameIndex,
            tableName: this.relativeTable.relativeFormId,
            columnValue: this.filterName,
            filterType: 3
          }
        ]
      }
      if (this.preParam.length > 0) {
        // 存在这样的情况,  如果脚本中配置了某变量的查询条件, 又在查询时配置了该变量, 需要覆盖脚本
        const map = new Map()
        this.preParam.forEach(m => {
          map.set(m.tableName + '-' + m.columnName, m)
        })
        param.filterDtoList.forEach(m => {
          map.set(m.tableName + '-' + m.columnName, m)
        })
        param.filterDtoList = [...map.values()]
      }
      OnlineFormController.listByDbLinkId(param).then((res) => {
        this.dataListCopy = res.data.dataList.data
        this.page.total = res.data.dataList.totalCount
        this.columns = res.data.head
      })
    },
    onCancel (isSuccess = false) {
      let data = this.dialogSelectItems.row
      let mapList = this.widget.props.relativeTable.mapList
      // 关联组件 映射过程
      for (let key in data) {
        mapList.map((item) => {
          if (item.selectFile === key) {
            data[item.formFile] = data[key]
          }
        })
      }
      if (data) delete data.id
      if (this.observer != null) {
        this.observer.cancel(isSuccess, data)
      } else {
        this.onCloseThirdDialog(isSuccess, this.thirdParams.path, this.dialogParams.value, this.dialogSelectItems)
      }
    },
    onSubmit () {
      this.onCancel(true)
    },
    onRadioChange (data) {
      // 如果选择的是被删除项 则返回
      if (data.row.delete_flag) return
      this.dialogSelectItems = data
    },
    initTreeData () {
      if (this.isTree) {
        const [parentId, id, name] = this.relativeTable.treeNode
        this.dataListCopy.forEach(item => {
          item.id = item[id]
          item.parentId = item[parentId]
        })
        let columns = this.head.map(m => ({
          field: m.columnName,
          title: m.columnComment
        }))
        columns.unshift({ type: 'seq', width: 60 })
        columns[1].type = 'radio'
        columns.find(m => m.field === name).treeNode = true

        this.gridOptions = {
          border: true,
          resizable: true,
          treeConfig: {
            transform: true,
            rowField: 'id',
            parentField: 'parentId'
          },
          proxyConfig: {
            ajax: {
              query: () => this.dataListCopy
            }
          },
          radioConfig: {
            labelField: 'id',
            // reserve: true,
            highlight: true
          },
          columns
        }
      }
    }
  },
  mounted () {
    this.dataListCopy = this.dataList
    this.page.total = this.total
    // 默认取 第一个 过滤参数
    if (this.filterFieldArray.length > 0) {
      const [first] = this.filterFieldArray
      this.filterNameIndex = first.key
      // console.log(this.filterNameIndex, 'this.filterNameIndex')
    }
    this.initTreeData()
  }
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  padding: 25px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tree-wrapper {
  overflow: scroll;
}
:deep(.vxe-radio--label) {
  display: none;
}
</style>
