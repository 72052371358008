// 引入自定义的图标
import '@/assets/font/index.scss';
import '@/assets/style/index.scss';
import '@/core/http';
import '@/core/mixins/global.js';
import '@/staticDict/onlineStaticDict.js';
import '@/staticDict/reportStaticDict.js';
import 'vant/lib/index.css';
import 'vxe-table/lib/style.css';

import App from './App';

import currency from 'currency.js';
import dayjs from 'dayjs';
import ElementUI from 'element-ui';
import JSONbig from 'json-bigint';

import Vue from 'vue';
import VXETable from 'vxe-table';

import RightAddBtn from '@/components/Btns/RightAddBtn';
import DateRange from '@/components/DateRange';
import DeptSelect from '@/components/DeptSelect';
import Dialog from '@/components/Dialog';
import FilterBox from '@/components/FilterBox';
import InputNumberRange from '@/components/InputNumberRange';
import RichEditor from '@/components/RichEditor';
import TableBox from '@/components/TableBox';
import TableProgressColumn from '@/components/TableProgressColumn';
import TimeFormat from '@/components/TimeFormat';
import TreeSelect from '@/components/TreeSelect';
import UserSelect from '@/components/UserSelect';
import OnlineBaseCard from '@/online/components/OnlineBaseCard.vue';
import OnlineCustomBlock from '@/online/components/OnlineCustomBlock.vue';
import OnlineCustomTabs from '@/online/components/OnlineCustomTabs.vue';
import OnlineCustomTableContainer from '@/online/components/OnlineTableContainer/index.vue';
import nodeWrap from '@/views/workflow/components/ProcessDingflowDesigner/nodeWrap';

import router from './router';
import store from './store';

Vue.component('OnlineCustomBlock', OnlineCustomBlock);
Vue.component('OnlineBaseCard', OnlineBaseCard);
Vue.component('OnlineCustomTabs', OnlineCustomTabs);
Vue.component('OnlineCustomTableContainer', OnlineCustomTableContainer);
Vue.component('nodeWrap', nodeWrap);

Vue.use(VXETable);
window.JSON = new JSONbig({storeAsString: true});
Vue.component('tree-select', TreeSelect);
Vue.component('rich-editor', RichEditor);
Vue.component('input-number-range', InputNumberRange);
Vue.component('date-range', DateRange);
Vue.component('filter-box', FilterBox);
Vue.component('table-progress-column', TableProgressColumn);
Vue.component('user-select', UserSelect);
Vue.component('dept-select', DeptSelect);
Vue.component('table-box', TableBox);
Vue.component('right-add-btn', RightAddBtn);
Vue.component('TimeFormat', TimeFormat)
Vue.use(ElementUI);
Vue.use(()=>import('v-charts'));

Vue.config.productionTip = false;

/* eslint-disable no-new */
const vm = new Vue({
  router,
  store,
  render: h => h(App)
});
vm.$mount('#app');
Vue.prototype.$vm = vm;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$dayjs = dayjs
Vue.prototype.$cy = currency
