export class MobileEntryController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/mobile/mobileEntry/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/delete', 'post', params, axiosOption, httpOption);
  }

  static uploadImage (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/uploadImage', 'post', params, axiosOption, httpOption);
  }

  static downloadImage (params, axiosOption, httpOption) {
    return doUrl('/admin/mobile/mobileEntry/downloadImage', 'post', params, axiosOption, httpOption);
  }
}
