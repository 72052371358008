export class OnlineDatasourceController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDatasource/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDatasource/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/online/onlineDatasource/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDatasource/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDatasource/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDatasource/delete', 'post', params, axiosOption, httpOption);
  }
}
