export class EsignController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/eFlow/list', 'post', params, axiosOption, httpOption);
  }

  static revoke (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/eFlow/revoke', 'post', params, axiosOption, httpOption);
  }
  static preview (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/eFlow/preview', 'GET', params, axiosOption, httpOption);
  }

  static reinvoke (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/eFlow/reinvoke', 'GET', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/eFlow/delete', 'POST', params, axiosOption, httpOption);
  }
}
