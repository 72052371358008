import axios from 'axios';

import dialog from '@/components/Dialog';
import router from '@/router';
import { getAppId, getToken, setToken } from '@/utils';

// 创建axios实例
const service = axios.create({
  timeout: 3000 * 10,
  withCredentials: false,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    'Content-Type': 'application/json; charset=utf-8',
    'deviceType': '4'
  },
  transformResponse: [
    function (data) {
      try {
        if (typeof data === 'string') {
          return JSON.parse(data);
        } else {
          return data;
        }
      } catch (error) {
        // console.error(error)
      }
    }
  ]
})

// request拦截器
service.interceptors.request.use(
  config => {
    let token = getToken();
    let menuIdJsonStr = window.sessionStorage.getItem('currentMenuId');
    let currentMenuId;
    if (menuIdJsonStr != null) {
      currentMenuId = (JSON.parse(menuIdJsonStr) || {}).data;
    }
    if (token != null) config.headers['Authorization'] = token;
    if (!!getAppId()) config.headers['AppCode'] = appId;
    if (currentMenuId != null) config.headers['MenuId'] = currentMenuId;
    return config
  }, error => {
    return Promise.reject(error)
  }
);

// response拦截器
service.interceptors.response.use(
  response => {
    if (response.data && response.data.errorCode === 'UNAUTHORIZED_LOGIN') { // 401, token失效
      setToken(null)
      dialog.closeAll();
      if (getAppId() == null) {
        router.push({ name: 'login' });
      } else {
        return Promise.reject(new Error({
          errorMessage: response.data.errorMessage
        }));
      }
    } else {
      if (response.headers['refreshedtoken'] != null) {
        setToken(response.headers['refreshedtoken']);
      }
    }
    return response
  }, error => {
    let response = error.response;
    if (response && response.data) {
      if (response.data.errorCode === 'UNAUTHORIZED_LOGIN') {
        dialog.closeAll();
        if (getAppId() == null) {
          router.push({ name: 'login' });
        } else {
          return Promise.reject(new Error({
            errorMessage: response.data.errorMessage
          }));
        }
      }

      return Promise.reject(response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service
