export class MccBatchProductController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/business/mccBatchProduct/export', params, fileName);
  }

  static startOnlyCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/startOnly/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startWithBusinessKeyCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/startWithBusinessKey/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startAndTakeUserTaskCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/startAndTakeUserTask/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startAndSaveDraftCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/startAndSaveDraft/cpsp', 'post', params, axiosOption, httpOption);
  }

  static submitUserTaskCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/submitUserTask/cpsp', 'post', params, axiosOption, httpOption);
  }

  static viewTaskBusinessDataCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/viewTaskBusinessData/cpsp', 'get', params, axiosOption, httpOption);
  }

  static viewHistoricTaskBusinessDataCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/viewHistoricTaskBusinessData/cpsp', 'get', params, axiosOption, httpOption);
  }

  static listWorkOrderCpsp (params, axiosOption, httpOption) {
    return doUrl('/admin/business/mccBatchProduct/listWorkOrder/cpsp', 'post', params, axiosOption, httpOption);
  }
}
