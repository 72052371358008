export class SysPostController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/sysPost/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/sysPost/view', 'get', params, axiosOption, httpOption);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/sysPost/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/sysPost/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/upms/sysPost/delete', 'post', params, axiosOption, httpOption);
  }
}
