export class FlowEntryController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/view', 'get', params, axiosOption, httpOption);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/delete', 'post', params, axiosOption, httpOption);
  }

  static publish (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/publish', 'post', params, axiosOption, httpOption);
  }

  static listFlowEntryPublish (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/listFlowEntryPublish', 'get', params, axiosOption, httpOption);
  }

  static updateMainVersion (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/updateMainVersion', 'post', params, axiosOption, httpOption);
  }

  static suspendFlowEntryPublish (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/suspendFlowEntryPublish', 'post', params, axiosOption, httpOption);
  }

  static activateFlowEntryPublish (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/activateFlowEntryPublish', 'post', params, axiosOption, httpOption);
  }

  static viewDict (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/viewDict', 'get', params, axiosOption, httpOption);
  }

  static listDict (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/listDict', 'get', params, axiosOption, httpOption);
  }
  static offline (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/offline', 'post', params, axiosOption, httpOption);
  }
  static accredit (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/flowEntryAccredit', 'post', params, axiosOption, httpOption);
  }
  static flowEntryRoleList (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntry/flowEntryRoleList', 'post', params, axiosOption, httpOption);
  }
}
