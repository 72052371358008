import { render, staticRenderFns } from "./carouselChart.vue?vue&type=template&id=2ff42c3a&scoped=true"
import script from "./carouselChart.vue?vue&type=script&lang=js"
export * from "./carouselChart.vue?vue&type=script&lang=js"
import style0 from "./carouselChart.vue?vue&type=style&index=0&id=2ff42c3a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_handlebars@_ucz5hgtaklppgz4ox5eoepgqae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff42c3a",
  null
  
)

export default component.exports