
export class ContactController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/contact/list', 'get', params, axiosOption, httpOption);
  }

  static create (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/contact/create', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/contact/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/contact/delete', 'post', params, axiosOption, httpOption);
  }

  static projects (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/contact/projects', 'get', params, axiosOption, httpOption);
  }
}
