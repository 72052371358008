export class OnlineOperation {
  static listDict (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/listDict', 'post', params, axiosOption, httpOption);
  }

  static listByDatasourceId (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/listByDatasourceId', 'post', params, axiosOption, httpOption);
  }

  static listByOneToManyRelationId (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/listByOneToManyRelationId', 'post', params, axiosOption, httpOption);
  }

  static addDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/addDatasource', 'post', params, axiosOption, httpOption);
  }

  static addOneToManyRelation (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/addOneToManyRelation', 'post', params, axiosOption, httpOption);
  }

  static updateDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/updateDatasource', 'post', params, axiosOption, httpOption);
  }

  static updateOneToManyRelation (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/updateOneToManyRelation', 'post', params, axiosOption, httpOption);
  }

  static viewByDatasourceId (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/viewByDatasourceId', 'get', params, axiosOption, httpOption);
  }

  static viewByOneToManyRelationId (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/viewByOneToManyRelationId', 'get', params, axiosOption, httpOption);
  }

  static deleteDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/deleteDatasource', 'post', params, axiosOption, httpOption);
  }

  static deleteOneToManyRelation (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/deleteOneToManyRelation', 'post', params, axiosOption, httpOption);
  }

  static getColumnRuleCode (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/getColumnRuleCode', 'get', params, axiosOption, httpOption);
  }

  static getPrintTemplate (params, axiosOption, httpOption) {
    return doUrl('/admin/report/reportPrint/listAll', 'post', params, axiosOption, httpOption);
  }
}
