export class OnlineFormController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/view', 'get', params, axiosOption, httpOption);
  }

  static render (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/render', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/online/onlineForm/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/delete', 'post', params, axiosOption, httpOption);
  }

  static clone (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/clone', 'post', params, axiosOption, httpOption);
  }
  static listByDbLinkId (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineOperation/listByDbLinkId ', 'post', params, axiosOption, httpOption);
  }

  static getNextFormCode (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineForm/getNextFormCode', 'post', params, axiosOption, httpOption);
  }
}
