export class OnlineDblinkController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/list', 'post', params, axiosOption, httpOption);
  }

  static listDblinkTables (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/listDblinkTables', 'get', params, axiosOption, httpOption);
  }

  static listDblinkTableColumns (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/listDblinkTableColumns', 'get', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/view', 'get', params, axiosOption, httpOption);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/delete', 'post', params, axiosOption, httpOption);
  }

  static testConnection (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDblink/testConnection', 'get', params, axiosOption, httpOption);
  }
}
