export class OnlinePageController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/list', 'post', params, axiosOption, httpOption);
  }

  static listAllPageAndForm (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/listAllPageAndForm', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/online/onlinePage/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/update', 'post', params, axiosOption, httpOption);
  }

  static updatePublished (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/updatePublished', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/delete', 'post', params, axiosOption, httpOption);
  }

  static updateStatus (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/updateStatus', 'post', params, axiosOption, httpOption);
  }

  static listOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/listOnlinePageDatasource', 'post', params, axiosOption, httpOption);
  }

  static listNotInOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/listNotInOnlinePageDatasource', 'post', params, axiosOption, httpOption);
  }

  static addOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/addOnlinePageDatasource', 'post', params, axiosOption, httpOption);
  }

  static deleteOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/deleteOnlinePageDatasource', 'post', params, axiosOption, httpOption);
  }

  static updateOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/updateOnlinePageDatasource', 'post', params, axiosOption, httpOption);
  }

  static viewOnlinePageDatasource (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/viewOnlinePageDatasource', 'get', params, axiosOption, httpOption);
  }
  static getFormUser (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlinePage/listAllPageAndUserDict', 'post', params, axiosOption, httpOption)
  }
}
