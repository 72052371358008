<template>
  <table-box
    ref="table"
    class="draggable-widget"
    show-overflow="title"
    show-header-overflow="title"
    header-cell-class-name="table-header-gray"
    style="height: 100%;"
    :data="finalDataList"
    :key="tableKey"
    :hasImageColumn="hasImageColumn"
    :size="defaultFormItemSize"
    :keep-source="rowEdit"
    :edit-config="editConfig"
    :seq-config="{ seqMethod }"
    :hasExtend="hasExtend"
    :sort-config="{ remote: remoteSort }"
    :row-config="{isCurrent: true, isHover: true, height: 120}"
    :radio-config="{labelField: 'name', trigger: 'row'}"
    :footer-method="footerMethod"
    :show-footer="widget.props.showFooter && finalDataList.length > 1"
    @sort-change="onSortChange"
    @checkbox-select-change="onCheckBoxChange"
    @radio-select-change="onRadioSelectChange"
    @refresh="onRefresh"
    @onEditClose="onEditClose">
    <template
      v-if="operationList.filter(row => { return row.enabled && !row.rowOperation }).length > 0 && !form().readOnly">
      <el-button slot="operator" icon="el-icon-menu" :size="defaultFormItemSize"
        v-if="operationVisible(SysCustomWidgetOperationType.MUTISELECT)"
        :type="getOperation(SysCustomWidgetOperationType.MUTISELECT).btnType"
        :plain="getOperation(SysCustomWidgetOperationType.MUTISELECT).plain"
        :disabled="operationDisabled(SysCustomWidgetOperationType.MUTISELECT)"
        @click="onOperationClick(getOperation(SysCustomWidgetOperationType.MUTISELECT))">
        {{ getOperation(SysCustomWidgetOperationType.MUTISELECT).name || '多选' }}
      </el-button>
      <el-button slot="operator" v-if="operationVisible(SysCustomWidgetOperationType.ADDLINE)" icon="el-icon-plus"
        :size="defaultFormItemSize"
        :type="getOperation(SysCustomWidgetOperationType.ADDLINE).btnType"
        :plain="getOperation(SysCustomWidgetOperationType.ADDLINE).plain"
        :disabled="operationDisabled(SysCustomWidgetOperationType.ADDLINE)"
        @click="onOperationClick(getOperation(SysCustomWidgetOperationType.ADDLINE))">
        {{ getOperation(SysCustomWidgetOperationType.ADDLINE).name || '新增一行' }}</el-button>
      <el-button v-if="operationVisible(SysCustomWidgetOperationType.BATCH_DELETE)" slot="operator"
        :size="defaultFormItemSize" :type="getOperation(SysCustomWidgetOperationType.BATCH_DELETE).btnType"
        :plain="getOperation(SysCustomWidgetOperationType.BATCH_DELETE).plain"
        :disabled="operationDisabled(SysCustomWidgetOperationType.BATCH_DELETE)"
        @click="onOperationClick(getOperation(SysCustomWidgetOperationType.BATCH_DELETE))">
        {{ getOperation(SysCustomWidgetOperationType.BATCH_DELETE).name || '批量删除' }}
      </el-button>
      <el-button slot="operator" icon="el-icon-plus" :size="defaultFormItemSize"
        v-if="operationVisible(SysCustomWidgetOperationType.ADD)"
        :type="getOperation(SysCustomWidgetOperationType.ADD).btnType"
        :plain="getOperation(SysCustomWidgetOperationType.ADD).plain"
        :disabled="operationDisabled(SysCustomWidgetOperationType.ADD)"
        @click="onOperationClick(getOperation(SysCustomWidgetOperationType.ADD))">
        {{ getOperation(SysCustomWidgetOperationType.ADD).name || '新建' }}
      </el-button>
      <el-button slot="operator" icon="el-icon-download" :size="defaultFormItemSize"
        v-if="operationVisible(SysCustomWidgetOperationType.EXPORT)"
        :type="getOperation(SysCustomWidgetOperationType.EXPORT).btnType"
        :plain="getOperation(SysCustomWidgetOperationType.EXPORT).plain"
        :disabled="operationDisabled(SysCustomWidgetOperationType.EXPORT)"
        @click="onOperationClick(getOperation(SysCustomWidgetOperationType.EXPORT))">
        {{ getOperation(SysCustomWidgetOperationType.EXPORT).name || '导出' }}
      </el-button>
      <el-button slot="operator" :size="defaultFormItemSize" v-if="operationVisible(SysCustomWidgetOperationType.IMPORT)"
        type="success" @click="downloadTempalte(getOperation(SysCustomWidgetOperationType.IMPORT))">
        模板下载
      </el-button>
      <el-upload slot="operator" style="line-height: 0; margin-left: 10px;display: inline-block;"
        v-if="operationVisible(SysCustomWidgetOperationType.IMPORT)" action :show-file-list="false"
        :size="defaultFormItemSize"
        :http-request="(file) => onUploadHandler(file, getOperation(SysCustomWidgetOperationType.IMPORT))">
        <el-button icon="el-icon-upload2" :size="defaultFormItemSize"
          :type="getOperation(SysCustomWidgetOperationType.IMPORT).btnType"
          :plain="getOperation(SysCustomWidgetOperationType.IMPORT).plain"
          :disabled="operationDisabled(SysCustomWidgetOperationType.IMPORT)">
          {{ getOperation(SysCustomWidgetOperationType.IMPORT).name || '导入' }}
        </el-button>
      </el-upload>
    </template>
    <slot slot="operator" name="operator" />
    <vxe-column v-if="hasBatchOperation && !form().readOnly" type="checkbox" :width="40" />
    <vxe-column v-if="singleSelect" type="radio" align="center" :width="50" />
    <vxe-column v-if="tableColumnList.length > 0" type="seq" title="序号" :width="50" />
    <template v-for="tableColumn in tableColumnList">
      <!-- Boolean类型字段 -->
      <vxe-column v-if="tableColumn.column && tableColumn.column.objectFieldType === 'Boolean'"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :key="tableColumn.columnId" :title="tableColumn.showName" :width="tableColumn.columnWidth">
        <template #default="{ row }">
          <el-tag :size="defaultFormItemSize" :type="row[tableColumn.showFieldName] ? 'success' : 'danger'">
            {{ row[tableColumn.showFieldName] ? '是' : '否' }}
          </el-tag>
        </template>
        <template #edit="{row }">
          <el-switch v-model="row[tableColumn.showFieldName]"></el-switch>
        </template>
      </vxe-column>
      <!-- 图片类型字段 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.fieldKind === SysOnlineFieldKind.UPLOAD_IMAGE"
        :key="'a' + tableColumn.columnId" :title="tableColumn.showName" :width="tableColumn.columnWidth">
        <template #default="{ row }">
          <el-image v-for="(item, index) in parseTableUploadData(tableColumn, row)"
            :preview-src-list="getTablePictureList(tableColumn, row)?.filter(m=>m.img).map(m=>m.url)" class="table-cell-image" :key="index"
            :src="item.url" fit="fill">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
          </el-image>
        </template>
      </vxe-column>
      <!-- 文件下载类型字段 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.fieldKind === SysOnlineFieldKind.UPLOAD"
        :key="'b' + tableColumn.columnId" :title="tableColumn.showName" :width="tableColumn.columnWidth">
        <template #default="{ row }">
          <div class="flex files-wrapper">
            <div v-for="(item, index) in parseTableUploadData(tableColumn, row)" :key="index" class="handler">
              <el-image
                style="width: 20px;margin: 10px 5px;"
                :src="defaultImage"
                :preview-src-list="parseTableUploadData(tableColumn, row).filter(m=>m.img).map(m=>m.url)"
                v-if="item.img">
              </el-image>
              <File-previwer :url="item.url" :name="item.name" v-else></File-previwer>
            </div>
          </div>
        </template>
      </vxe-column>
      <!-- 时间类型 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.columnType === 'datetime'"
        :width="tableColumn.columnWidth" :title="tableColumn.showName" :field="tableColumn.showFieldName"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :sortable="tableColumn.sortable" :key="'time' + tableColumn.columnId">
        <template #edit="{ row }">
          <vxe-input v-model="row[tableColumn.showFieldName]" placeholder="日期和时间选择" type="datetime" transfer></vxe-input>
        </template>
        <template #default="{ row }">
          <Time-Format :time="getDeepColumnvalue(tableColumn.showFieldName, row)" type="1" :key="row._X_ROW_KEY"></Time-Format>
        </template>
      </vxe-column>
      <!-- 时间戳形式 -->
      <vxe-column v-else-if="tableColumn.isTimestamp"
        :width="tableColumn.columnWidth" :title="tableColumn.showName" :field="tableColumn.showFieldName"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :sortable="tableColumn.sortable" :key="'time' + tableColumn.columnId">
        <template #edit="{ row }">
          <vxe-input v-model="row[tableColumn.showFieldName]" placeholder="日期和时间选择" type="datetime" transfer></vxe-input>
        </template>
        <template #default="{ row }">
          <Time-Format :time="getDeepColumnvalue(tableColumn.showFieldName, row)" type="1" :key="row._X_ROW_KEY"></Time-Format>
        </template>
      </vxe-column>
      <!-- 时间类型  精确到日 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.columnType === 'date'"
        :width="tableColumn.columnWidth" :title="tableColumn.showName" :field="tableColumn.showFieldName"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :sortable="tableColumn.sortable" :key="'date' + tableColumn.columnId">
        <template #edit="{ row }">
          <vxe-input v-model="row[tableColumn.showFieldName]" placeholder="日期和时间选择" type="date" transfer></vxe-input>
        </template>
        <template #default="{ row }">
          <Time-Format :time="getDeepColumnvalue(tableColumn.showFieldName, row)" type="2" :key="row._X_ROW_KEY"></Time-Format>
        </template>
      </vxe-column>
      <!-- Number 类型 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.columnType === 'decimal'" :key="'c' + tableColumn.columnId"
        :title="tableColumn.showName" v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :width="tableColumn.columnWidth" :field="tableColumn.showFieldName" :sortable="tableColumn.sortable">
        <template #edit="{ row }" v-if="!tableColumn.disabledEdit">
          <el-input oninput="value=value.replace(/[^-\d.]+|(?<=-)-/g,'')" v-model="row[tableColumn.showFieldName]" clearable></el-input>
        </template>
        <template #default="{ row }" v-if="tableColumn.isMoney">
          <span style="color: #f56c6c" v-if="row[tableColumn.showFieldName]">
            {{ $cy(row[tableColumn.showFieldName], {precision: 2, symbol: '￥'}).format() }}
          </span>
        </template>
      </vxe-column>
      <!-- 下拉选择 字典 -->
      <vxe-column v-else-if="tableColumn.column && tableColumn.column.dictInfo"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :title="tableColumn.showName" :width="tableColumn.columnWidth" :field="tableColumn.showFieldName"
        :sortable="tableColumn.sortable" :key="'dict' + tableColumn.columnId">
        <template #edit="{ row }">
          <vxe-select v-model="row[tableColumn.column.columnName]"
            @change="(value) => onInnerSelectChange(value, row, tableColumn.column.columnName, getDict(tableColumn.column.dictInfo))">
            <vxe-option v-for="op in getDict(tableColumn.column.dictInfo)" :key="op.id" :value="op.id"
              :label="op.name"></vxe-option>
          </vxe-select>
        </template>
      </vxe-column>
      <vxe-column v-else :key="'d' + tableColumn.columnId" :title="tableColumn.showName"
        v-bind="editRenderConfig(tableColumn.disabledEdit)"
        :width="tableColumn.columnWidth" :field="tableColumn.showFieldName" :sortable="tableColumn.sortable">
        <template #edit="{ row }" v-if="!tableColumn.disabledEdit">
          <el-input v-model="row[tableColumn.showFieldName]" clearable></el-input>
        </template>
      </vxe-column>
    </template>
    <vxe-column title="操作" :width="widget.props.operationColumnWidth || 160" fixed="right" :show-overflow="false"
      v-if="(rowEdit || tableOperationList.length > 0) && tableColumnList.length > 0">
      <template #default="{ row }">
        <div class="flex align-center">
          <el-button v-for="operation in tableOperationList" :key="operation.id"
            v-show="form().checkOperationVisible(operation, row) && ![SysCustomWidgetOperationType.EXPORT, SysCustomWidgetOperationType.IMPORT].includes(operation.type)"
            :size="defaultFormItemSize" type="text" :class="operation.btnClass" :plain="operation.plain"
            :disabled="!form().checkOperationPermCode(operation) || form().checkOperationDisabled(operation, row)"
            @click="onOperationClick(operation, row)">
            {{ operation.name }}
          </el-button>
          <template v-if="inlineActived && isActiveByRow(row)">
            <el-button @click="saveRowEvent(row)" type="success" size="mini">保存</el-button>
            <el-button @click="cancelRowEvent(row)" type="warn" size="mini">取消</el-button>
          </template>
          <template v-if="inlineActived && !isActiveByRow(row)">
            <el-button @click="editRowEvent(row)" type="primary" size="mini">编辑</el-button>
          </template>
        </div>
      </template>
    </vxe-column>
    <slot slot="pagination" name="pagination" />
  </table-box>
</template>

<script>
import Axios from 'axios';
import { throttle, debounce } from 'lodash';

import FilePreviwer from '@/components/FilePreviewer';
import { uploadMixin } from '@/core/mixins';
import { findItemFromList } from '@/utils';

export default {
  name: 'OnlineCustomTable',
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    border: {
      type: String,
      default: 'full'
    },
    // 是否支持行内编辑
    rowEdit: {
      type: Boolean,
      default: false
    },
    // 是否支持多选
    // multiSelect: {
    //   type: Boolean,
    //   default: false
    // },
    // 是否支持单选
    singleSelect: {
      type: Boolean,
      default: false
    },
    // 表格操作列表
    operationList: {
      type: Array,
      default: () => []
    },
    widget: {
      type: Object
    },
    // 获取行序号
    getTableIndex: {
      type: Function
    },
    // 排序改变
    sortChange: {
      type: Function
    },
    // 多选选中改变
    onSelectChange: {
      type: Function
    },
    // 单选中改变
    onRadioChange: {
      type: Function
    },
    cellEdit: {
      type: Boolean,
      default: false
    },
  },
  inject: ['form'],
  mixins: [uploadMixin],
  components: {FilePreviwer},
  data () {
    return {
      editInfo: {
        editRow: undefined
      },
      sortInfo: undefined,
      editConfig: undefined,
      inlineActived: false,
      defaultImage: require('@/assets/img/image.png'),
      ExcelImg: require('@/assets/img/Excel-Color.png'),
      PdfImg: require('@/assets/img/Pdf-Color.png'),
      WordImg: require('@/assets/img/Word-Color.png'),
      fileImage: require('@/assets/img/document.png'),
      // 是否需要额外一层过滤
      extraFilter: false,
    }
  },
  methods: {
    footerMethod: function () {
      return [
        ['合计', ...this.tableColumnList.map(m=> m.needSum ? this.sumByKeyFromDataList(m.showFieldName) || '' : '')]
      ];
    },
    // 根据键值 从表格数据中 求出小计
    sumByKeyFromDataList (key) {
      return this.finalDataList.reduce((pre, cur) => this.$cy(cur[key] || 0).add(pre).value, 0)
    },
    // 读取深层次值
    getDeepColumnvalue (column, row) {
      // 获取key的数组 可能为多个 也可能只有一个
      let keys = column.split('.')
      // 遍历keys 读取值
      return keys.reduce((input, key) => {
        return input[key]
      }, row)
    },
    dealDictNull (info) {
      const {dictDataJson, dictId} = info
      const temp = JSON.parse(dictDataJson).dictData
      if (!temp) {
        doUrl('/admin/online/onlineOperation/listDict', 'post', { dictId }).then(res => {
          info.fullDict = res.data
        })
      }
    },
    editRenderConfig (bool) {
      return bool ? {} : {
        'edit-render': {}
      }
    },
    preview (url) {
      if (url.match(/\.(pdf)$/i)) window.open(url, '_blank')
      else window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url), '_blank')
    },
    downloadUri (url, name) {
      Axios.get(url, { responseType: 'blob' }).then(res => {
        const blob = new Blob([res.data])
        let a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = name
        a.click()
      })
    },
    onEditClose () {
      // 单元格编辑之后 需要触发的事件
      if (this.form().eventInfo.afterLoadFormData instanceof Function) this.form().eventInfo.afterLoadFormData()
    },
    onInnerSelectChange ({ value }, row, key, dict) {
      // 这里进来的必定是 字典
      const columnName = key + 'DictMap'
      row[columnName] = dict.find(m => m.id === value)
    },
    onRefresh () {
      this.$emit('refresh');
    },
    hasOperator (type) {
      let temp = this.getOperation(type);
      return temp && temp.enabled;
    },
    getOperation (type) {
      return findItemFromList(this.operationList, type, 'type');
    },
    operationVisible (type) {
      let operation = this.getOperation(type);
      return !this.form().readOnly && this.hasOperator(type) && this.form().checkOperationVisible(operation);
    },
    operationDisabled (type) {
      let operation = this.getOperation(type);
      return this.form().checkOperationDisabled(operation) || !this.form().checkOperationPermCode(operation);
    },
    seqMethod (data) {
      if (this.getTableIndex) {
        return this.getTableIndex(data.seq - 1);
      } else {
        return data.seq;
      }
    },
    onSortChange (data) {
      if (!this.widget.props.paged) return;
      let fieldName = data.property.replace('DictMap.name', '');
      let order = data.order;
      if (order == null) {
        fieldName = undefined;
      }
      if (order === 'asc') order = 'ascending';
      if (this.sortInfo != null && this.sortInfo.prop === fieldName && this.sortInfo.order === order) {
        return;
      }
      this.sortInfo = {
        prop: fieldName,
        order: order
      }
      if (this.sortChange) {
        this.sortChange(this.sortInfo)
      }
    },
    onCheckBoxChange () {
      if (this.$refs.table && typeof this.onSelectChange === 'function') {
        let selectRows = this.$refs.table.getTableImpl().getCheckboxRecords(true);
        this.onSelectChange(selectRows);
      }
    },
    onRadioSelectChange () {
      if (this.$refs.table && typeof this.onRadioChange === 'function') {
        let selectRow = this.$refs.table.getTableImpl().getRadioRecord();
        this.onRadioChange(selectRow);
      }
    },
    setSelectedRow (rowNum) {
      this.$refs.table.getTableImpl().setRadioRow(this.dataList[rowNum]);
      this.$nextTick(this.onRadioSelectChange);
    },
    isActiveByRow (row) {
      return this.$refs.table.getTableImpl().isEditByRow(row)
    },
    // 取消行内编辑
    cancelRowEvent (row) {
      if (this.form().isEdit) return;
      this.$refs.table.getTableImpl().clearActived().then(() => {
        // 还原行数据
        this.$refs.table.getTableImpl().revertData(row);
        this.editInfo.editRow = undefined;
      }).catch(e => { });
    },
    // 启动行内编辑
    editRowEvent (row) {
      if (this.form().isEdit) return;
      this.$refs.table.getTableImpl().setEditRow(row);
      this.editInfo.editRow = row;
    },
    // 保存行内编辑数据
    saveRowEvent (row) {
      // 设计模式下 不处理
      if (this.form().isEdit) return;
      this.$refs.table.getTableImpl().clearActived().then(() => {
        this.$refs.table.getTableImpl().reloadRow(row);
        this.onSaveRowData(row);
        this.editInfo.editRow = undefined;
      });
    },
    onSaveRowData (row) {
      const { masterTableId, tableMap } = this.form()
      // 主表
      const materTable = tableMap.get(masterTableId)

      const params = {
        datasourceId: materTable.datasource.datasourceId,
        masterData: row
      }
      const commitUrl = '/admin/online/onlineOperation/updateDatasource/' + materTable.datasource.variableName
      // 查询表单 肯定为主表   编辑表单 不需要此处调用保存
      doUrl(commitUrl, 'post', params).then(res => {
        this.$message.success('保存成功！');
      }).catch(e => {
        console.log(e)
      });
    },
    // 新建行内编辑数据
    addRowEvent (row) {
      if (this.form().isEdit) return;
      this.$refs.table.getTableImpl().insertAt(row, 0).then(res => {
        let newRow = res.row;
        this.$refs.table.getTableImpl().setEditRow(newRow);
      });
    },
    // 删除行数据
    deleteRowEvent (row) {
      if (this.form().isEdit) return;
      this.$emit('delete', row);
    },
    onOperationClick: throttle (function (operation, row) {
      // 第三个参数 用于 表格多选时的 数据源过滤
      this.$emit('operationClick', operation, row, this.widget.props.filterSettings)
    }, 2000, { trailing: false }),
    // 模板下载使用 独立方法
    downloadTempalte (operation) {
      // 没有字段时 直接报错
      if (!Array.isArray(operation.importColumnList) || operation.importColumnList.length === 0) return this.$message.error('未添加导入字段!')
      operation.importColumnList.forEach((m, i) => { m.showOrder = i })
      this.$confirm('是否下载导入模板？')
        .then(() => {
          let params = {
            importColumnList: operation.importColumnList.sort((val1, val2) => val1.showOrder - val2.showOrder)
          }
          let httpCall
          httpCall = this.download('/admin/online/onlineOperation/downloadImportTpl', params, this.widget.showName + '_模板.xlsx')
          httpCall.then(() => {
            this.$message.success('下载成功！')
          })
        })
    },
    //
    onUploadHandler (file, operation) {
      this.$emit('onFileSelect', file, this.widget.datasource, operation.importColumnList)
    },
    refreshColumn () {
      this.$nextTick(() => {
        if (this.$refs.table) this.$refs.table.getTableImpl().refreshColumn();
      });
    },
    parseTableUploadData (tableColumn, row) {
      let jsonData = row[tableColumn.showFieldName] || [];
      let res = []
      if (typeof jsonData === 'string') {
        try {
          res = JSON.parse(jsonData)
        } catch (error) {
          console.log('json 字符串解析失败')
        }
      } else {
        res = jsonData
      }
      if (Array.isArray(res)) {
        res.forEach(m => {
          // 可能会出现上传失败 导致没有url 字段
          if (m.url && m.url.match(/\.(jpeg|jpg|png|gif)$/i)) m.img = true
        })
      }
      return res
    },
    getTablePictureList (tableColumn, row) {
      let temp = this.parseTableUploadData(tableColumn, row);
      if (Array.isArray(temp)) {
        return temp
      } else {
        return []
      }
    },
    formatListData (data) {
      // 修复 徐天龙 修改数据库 导致的BUG,  这样修改之后 不确保不会产生新的BUG
      if (!data) return
      Object.keys(data).forEach(key => {
        let subData = data[key];
        if (typeof subData === 'object' && key.indexOf('DictMap') === -1) {
          this.formatListData(subData);
        } else {
          // 如果是多选字典字段，那么把选中的字典值拼接成DictMap去显示
          if (key.indexOf('DictMapList') !== -1 && Array.isArray(data[key])) {
            let newKey = key.replace('DictMapList', 'DictMap');
            data[newKey] = {
              id: data[key.replace('DictMapList', '')],
              name: data[key].map(subItem => subItem.name).join(',')
            }
          }
        }
      });
    },
    activeCellMethod () {
      return true
    }
  },
  computed: {
    buildFlowParam () {
      let flowParam = {};
      let flowData = this.form().flowData;
      if (flowData) {
        if (flowData.processDefinitionKey) flowParam.processDefinitionKey = flowData.processDefinitionKey;
        if (flowData.processInstanceId) flowParam.processInstanceId = flowData.processInstanceId;
        if (flowData.taskId) flowParam.taskId = flowData.taskId;
      }

      return flowParam;
    },
    tableColumnList () {
      let tempList = (this.widget && this.widget.props && Array.isArray(this.widget.props.tableColumnList)) ? this.widget.props.tableColumnList : [];
      tempList.forEach(item => {
        if (item.fieldType === 0 || item.fieldType == null) {
          // 绑定表字段
          if (item.column) item.showFieldName = item.column.columnName;
          if (this.widget.relation == null && item.relation != null) {
            item.showFieldName = item.relation.variableName + '.' + item.showFieldName
          }
          if (item.column && item.column.dictInfo) {
            item.showFieldName = item.showFieldName + 'DictMap.name';
            // 如果字典中 没有字典数据 则 请求
            this.dealDictNull(item.column.dictInfo)
          }
        } else {
          // 自定义字段
          item.showFieldName = item.customFieldName;
        }
      });
      return tempList;
    },
    tableOperationList () {
      return this.operationList.filter(item => {
        let temp = item.enabled && item.rowOperation;
        if (temp && this.form().readOnly) {
          temp = temp && item.readOnly;
        }
        return temp;
      });
    },
    hasBatchOperation () {
      let batchDeleteOperation = findItemFromList(this.operationList, this.SysCustomWidgetOperationType.BATCH_DELETE, 'type');
      let printOperation = findItemFromList(this.operationList, this.SysCustomWidgetOperationType.PRINT, 'type');
      return (batchDeleteOperation != null && batchDeleteOperation.enabled) || (printOperation != null && printOperation.enabled && !printOperation.rowOperation);
    },
    hasImageColumn () {
      return this.tableColumnList.filter(tableColumn => {
        return tableColumn.column && tableColumn.column.fieldKind === this.SysOnlineFieldKind.UPLOAD_IMAGE;
      }).length > 0;
    },
    tableKey () {
      return (this.widget || {}).variableName + new Date().getTime() + this.tableColumnList.length;
    },
    remoteSort () {
      return this.widge ? (this.widget.props || {}).paged : false;
    },
    hasExtend () {
      return (this.operationList.filter(row => { return row.enabled && !row.rowOperation }).length > 0 && !this.form().readOnly) || (this.$slots.operator != null);
    },
    getDict () {
      return function ({dictDataJson, fullDict}) {
        return JSON.parse(dictDataJson).dictData || fullDict
      }
    },
    finalDataList () {
      if (this.extraFilter) {
        const [filt] = this.widget.props.dataFilter
        return this.dataList.filter(item => {
          const res = this.getDeepColumnvalue(filt.key, item)
          const user = this.getUserInfo[filt.value]
          return res == user
        })
      } else {
        return this.dataList;
      }
    }
  },
  created(){
    // 新增规则, 如果表格的配置中 存在dataFilter 则需要在表格数据的基础上 额外增加一层过滤
    // console.log('表格组件: ', this.widget, this.form())
    if (Array.isArray(this.widget.props.dataFilter) && this.widget.props.dataFilter.length > 0) {
      this.extraFilter = true
    }
  },
  watch: {
    dataList: {
      handler () {
        if (Array.isArray(this.dataList)) {
          this.dataList.forEach(item => {
            this.formatListData(item);
          });
        }
      },
      immediate: true
    },
    tableColumnList: {
      handler () {
        this.refreshColumn();
      },
      immediate: true
    },
    'widget.props.operationColumnWidth': {
      handler () {
        this.refreshColumn();
      }
    },
    rowEdit: {
      handler (n) {
        if (this.cellEdit) return
        if (n && !this.form().readOnly) {
          this.editConfig = { trigger: 'manual', mode: 'row'}
          this.inlineActived = true
        } else {
          this.editConfig = false
          this.inlineActived = false
        }
      },
      immediate: true
    },
    cellEdit: {
      handler (n) {
        if (this.rowEdit) return
        if (n && !this.form().readOnly) this.editConfig = { trigger: 'click', mode: 'cell', beforeEditMethod: this.activeCellMethod }
        else this.editConfig = false
      },
      immediate: true
    }
  }
}
</script>
