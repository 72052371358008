export class FlowEntryVariableController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntryVariable/list', 'post', params, axiosOption, httpOption);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntryVariable/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntryVariable/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntryVariable/delete', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowEntryVariable/view', 'get', params, axiosOption, httpOption);
  }
}
