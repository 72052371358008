export class SealController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/seal/list', 'get', params, axiosOption, httpOption);
  }

  // static view (params, axiosOption, httpOption) {
  //   return doUrl('/seal/view', 'get', params, axiosOption, httpOption);
  // }

  static create (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/seal/create', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/seal/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/nuxtapi/seal/delete', 'post', params, axiosOption, httpOption);
  }
}
