export class OnlineDictController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/online/onlineDict/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/delete', 'post', params, axiosOption, httpOption);
  }

  static listAllGlobalDict (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineDict/listAllGlobalDict', 'post', params, axiosOption, httpOption);
  }
}
