import * as staticDict from '@/staticDict';

export class FlowOperationController {
  // 保存草稿
  static startAndSaveDraft (params, axiosOption, httpOption) {
    let url = '/admin/flow/flowOnlineOperation/startAndSaveDraft';
    if (axiosOption && axiosOption.processDefinitionKey) {
      url += '/' + axiosOption.processDefinitionKey;
    }
    return doUrl(url, 'post', params, axiosOption, httpOption);
  }
  // 获取在线表单工作流草稿数据
  static viewOnlineDraftData (params, axiosOption, httpOption) {
    let url = '/admin/flow/flowOnlineOperation/viewDraftData';
    return doUrl(url, 'get', params, axiosOption, httpOption);
  }
  // 启动流程实例并且提交表单信息
  static startAndTakeUserTask (params, axiosOption, httpOption) {
    let url = '/admin/flow/flowOnlineOperation/startAndTakeUserTask';
    if (axiosOption && axiosOption.processDefinitionKey) {
      url += '/' + axiosOption.processDefinitionKey;
    } else {
      // 从流程设计里启动
      url = '/admin/flow/flowOnlineOperation/startPreview';
    }
    return doUrl(url, 'post', params, axiosOption, httpOption);
  }
  // 获得流程以及工单信息
  static listWorkOrder (params, axiosOption, httpOption) {
    let url = '/admin/flow/flowOnlineOperation/listWorkOrder';
    if (axiosOption && axiosOption.processDefinitionKey) {
      url += '/' + axiosOption.processDefinitionKey;
    }
    return doUrl(url, 'post', params, axiosOption, httpOption);
  }
  // 提交用户任务数据
  static submitUserTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/submitUserTask', 'post', params, axiosOption, httpOption);
  }
  // 获取历史流程数据
  static viewHistoricProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/viewHistoricProcessInstance', 'get', params, axiosOption, httpOption);
  }
  // 获取用户任务数据
  static viewUserTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/viewUserTask', 'get', params, axiosOption, httpOption);
  }
  // 获取在线表单工作流以及工作流下表单列表
  static listFlowEntryForm (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/listFlowEntryForm', 'get', params, axiosOption, httpOption);
  }
  // 获得草稿信息
  static viewDraftData (params, axiosOption, httpOption) {
    let url = '/admin/flow/flowOperation/viewDraftData';
    return doUrl(url, 'get', params, axiosOption, httpOption);
  }
  // 撤销工单
  static cancelWorkOrder (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/cancelWorkOrder', 'post', params, axiosOption, httpOption);
  }
  // 重新发起
  static cancelReEditWorkOrder (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/cancelReEditWorkOrder', 'post', params, axiosOption, httpOption);
  }

  // 多实例加签
  static submitConsign (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/submitConsign', 'post', params, axiosOption, httpOption);
  }
  // 已办任务列表
  static listHistoricTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listHistoricTask', 'post', params, axiosOption, httpOption);
  }
  // 我发起的
  static listMyInitiative (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listMyInitiative', 'post', params, axiosOption, httpOption);
  }
  // 获取已办任务信息
  static viewHistoricTaskInfo (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewHistoricTaskInfo', 'get', params, axiosOption, httpOption);
  }
  // 仅启动流程实例
  static startOnly (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/startOnly', 'post', params, axiosOption, httpOption);
  }
  // 获得流程定义初始化用户任务信息
  static viewInitialTaskInfo (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewInitialTaskInfo', 'get', params, axiosOption, httpOption);
  }
  // 获取待办任务信息
  static viewRuntimeTaskInfo (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewRuntimeTaskInfo', 'get', params, axiosOption, httpOption);
  }
  // 获取流程实例审批历史
  static listFlowTaskComment (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listFlowTaskComment', 'get', params, axiosOption, httpOption);
  }
  // 获取历史任务信息
  static viewInitialHistoricTaskInfo (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewInitialHistoricTaskInfo', 'get', params, axiosOption, httpOption);
  }
  // 获取所有待办任务
  static listRuntimeTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listRuntimeTask', 'post', params, axiosOption, httpOption);
  }
  // 获得流程实例审批路径
  static viewHighlightFlowData (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewHighlightFlowData', 'get', params, axiosOption, httpOption);
  }
  // 获得流程实例的配置XML
  static viewProcessBpmn (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewProcessBpmn', 'get', params, axiosOption, httpOption);
  }
  // 获得所有历史流程实例
  static listAllHistoricProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listAllHistoricProcessInstance', 'post', params, axiosOption, httpOption);
  }
  // 获得当前用户历史流程实例
  static listHistoricProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listHistoricProcessInstance', 'post', params, axiosOption, httpOption);
  }
  // 终止流程
  static stopProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/stopProcessInstance', 'post', params, axiosOption, httpOption);
  }
  // 删除流程实例
  static deleteProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/deleteProcessInstance', 'post', params, axiosOption, httpOption);
  }
  // 催办
  static remindRuntimeTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/remindRuntimeTask', 'post', params, axiosOption, httpOption);
  }
  // 催办消息列表
  static listRemindingTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowMessage/listRemindingTask', 'post', params, axiosOption, httpOption);
  }
  // 驳回
  static rejectRuntimeTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/rejectRuntimeTask', 'post', params, axiosOption, httpOption);
  }
  // 驳回到起点
  static rejectToStartUserTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/rejectToStartUserTask', 'post', params, axiosOption, httpOption);
  }
  // 撤销
  static revokeHistoricTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/revokeHistoricTask', 'post', params, axiosOption, httpOption);
  }
  // 抄送消息列表
  static listCopyMessage (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowMessage/listCopyMessage', 'post', params, axiosOption, httpOption);
  }
  // 消息个数
  static getMessageCount (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowMessage/getMessageCount', 'get', params, axiosOption, httpOption);
  }
  // 在线表单流程抄送消息数据
  static viewOnlineCopyBusinessData (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/viewCopyBusinessData', 'get', params, axiosOption, httpOption);
  }
  // 静态表单流程抄送消息数据
  static viewCopyBusinessData (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewCopyBusinessData', 'get', params, axiosOption, httpOption);
  }
  // 获取指定任务处理人列表
  static viewTaskUserInfo (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/viewTaskUserInfo', 'get', params, axiosOption, httpOption);
  }
  // 获取驳回历史任务列表
  static listRejectCandidateUserTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listRejectCandidateUserTask', 'get', params, axiosOption, httpOption);
  }
  // 获取多实例任务中会签人员列表
  static listMultiSignAssignees (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listMultiSignAssignees', 'get', params, axiosOption, httpOption);
  }
  // 流程干预
  static interveneRuntimeTask (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/interveneRuntimeTask', 'post', params, axiosOption, httpOption);
  }
  // 自由跳
  static freeJump (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/freeJumpTo', 'post', params, axiosOption, httpOption);
  }
  // 数据补偿
  static fixBusinessData (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/fixBusinessData', 'post', params, axiosOption, httpOption);
  }
  static listWorkOrders (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOperation/listWorkOrder', 'post', params, axiosOption, httpOption);
  }
  static dictFlowDefName (params, axiosOption, httpOption) {
    return new Promise((resolve, reject) => {
      doUrl('/admin/flow/flowOperation/dictFlowDefName', 'post', params, axiosOption, httpOption).then(res => {
        let dictData = new staticDict.DictionaryBase();
        dictData.setList(res.data);
        resolve(dictData);
      }).catch(err => {
        reject(err);
      });
    });
  }
  static clearProcessInstance (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/clearProcessInstanceId', 'post', params, axiosOption, httpOption);
  }
  static initialWorkOrderServiceField (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/initialWorkOrderServiceField', 'post', params, axiosOption, httpOption);
  }

  static esignFlowCompensateCheck (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/esignFlowCompensateCheck', 'post', params, axiosOption, httpOption);
  }
  static esignFlowCompensate (params, axiosOption, httpOption) {
    return doUrl('/admin/flow/flowOnlineOperation/esignFlowCompensate', 'post', params, axiosOption, httpOption);
  }
}
