export class OnlineColumnController {
  static list (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('/admin/online/onlineColumn/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/update', 'post', params, axiosOption, httpOption);
  }

  static refreshColumn (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/refresh', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/delete', 'post', params, axiosOption, httpOption);
  }

  static listOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/listOnlineColumnRule', 'post', params, axiosOption, httpOption);
  }

  static listNotInOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/listNotInOnlineColumnRule', 'post', params, axiosOption, httpOption);
  }

  static addOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/addOnlineColumnRule', 'post', params, axiosOption, httpOption);
  }

  static deleteOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/deleteOnlineColumnRule', 'post', params, axiosOption, httpOption);
  }

  static updateOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/updateOnlineColumnRule', 'post', params, axiosOption, httpOption);
  }

  static viewOnlineColumnRule (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/viewOnlineColumnRule', 'get', params, axiosOption, httpOption);
  }
  static getTableById (params, axiosOption, httpOption) {
    return doUrl('/admin/online/onlineColumn/getTableById', 'post', params, axiosOption, httpOption);
  }
}
