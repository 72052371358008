export class SysDeptController {
  static list (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/list', 'post', params, axiosOption, httpOption);
  }

  static view (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/view', 'get', params, axiosOption, httpOption);
  }

  static export (params, fileName) {
    return download('admin/upms/sysDept/export', params, fileName);
  }

  static add (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/add', 'post', params, axiosOption, httpOption);
  }

  static update (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/update', 'post', params, axiosOption, httpOption);
  }

  static delete (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/delete', 'post', params, axiosOption, httpOption);
  }

  static listNotInSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/listNotInSysDeptPost', 'post', params, axiosOption, httpOption);
  }

  static listSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/listSysDeptPost', 'post', params, axiosOption, httpOption);
  }

  static addSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/addSysDeptPost', 'post', params, axiosOption, httpOption);
  }

  static updateSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/updateSysDeptPost', 'post', params, axiosOption, httpOption);
  }

  static deleteSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/deleteSysDeptPost', 'post', params, axiosOption, httpOption);
  }

  static viewSysDeptPost (params, axiosOption, httpOption) {
    return doUrl('admin/upms/sysDept/viewSysDeptPost', 'get', params, axiosOption, httpOption);
  }
}
